import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';

import App from './App';
import { ThemeProvider } from 'hooks';
import { store } from 'redux/root.store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://19c1c5480b81d47ffa2f76ff26c65b8d@o4508098641264640.ingest.de.sentry.io/4508098644213840',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://api-dev.drivex.io', 'https://api.drivex.io', 'https://api.drivex.io'],
  profilesSampleRate: 1.0,
  // In dev env we sample only 1/10 of the replays
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <LocalizeProvider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </LocalizeProvider>
  </ReduxProvider>,
  document.getElementById('appRoot')
);
reportWebVitals();
serviceWorker.unregister();
