import { __dev__, __prod__, parseUrlId } from 'utils';
import queryString from 'querystring';
import * as Sentry from '@sentry/react';

export type IdentifyUserProps = {
  // Id of the currently logged in user
  id: string,
  email: string,
  customerCode: string,
  customerName: string,
  publicUserPhotoSeriesRecipient: string,
  returnUrl: string,
  photoSeries: { id: string, vehicle: { registrationNo: string, vin: string } }
  sessionExpiresAt: string,
};

export const identifyUser = (props: IdentifyUserProps) => {
  const query = queryString.parse(window.location.search.substr(1));
  const { urlId } = query;

  if (!parseUrlId(urlId)) return;

  const data = {
    userId: parseUrlId(urlId),
    displayName: props.photoSeries.vehicle.vin || '',
    email: props.email || '',
    app: `DriveX SmartScan${__dev__ ? ' [DEV]' : ''}`,
    customerCode: props.customerCode || '',
    customerName: props.customerName || '',
    returnUrl: props.returnUrl || '',
    vin: props.photoSeries.vehicle.vin || '',
    registrationNo: props.photoSeries.vehicle.registrationNo || '',
    photoSeriesId: props.photoSeries.id || '',
    isLive_bool: __prod__
  };

  Sentry.setUser(data);
};
